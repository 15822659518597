import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function TermsCondition() {
  return (
    <>
      <Navbar />
      <div className="mb-5">
        <section id="terms-of-use" className="terms-of-use section">
          <Container>
            <Row>
              <Col >
                <div className="section-title ">
                  {/* <h3 className="wow zoomIn" data-wow-delay=".2s">Terms of Use</h3> */}
                  <h2 className="wow fadeInUp mt-5" data-wow-delay=".4s">
                    Khet-E Software Agreement
                  </h2>
                </div>
                <div className="terms-content wow fadeInUp" data-wow-delay=".6s">
                  <p>
                    This Khet-E Software Agreement (“Software Agreement”) is a
                    legal agreement between you the and Khet-E Management Private
                    Limited ("Khet-E ") regarding the provision of, license to,
                    access to, and use of Khet-E 's mobile, desktop, and web
                    applications, including Farmers, procurers and supply chain
                    experts, and application programming interfaces ("API"), as
                    well as analytics services offered through Khet-E
                    (collectively and individually the "Software Services"). In
                    this Software Agreement, "you,” "your," and “yourself” refer
                    collectively to you, the customer, and any corporation,
                    partnership, company or other business entity you represent,
                    and "we", "us" and "our" refer collectively to Khet-E. You and
                    Khet-E may be individually referred to as a "party" or
                    collectively as the "parties."
                  </p>

                  <h6>IMPORTANT:</h6>
                  <p>
                    Please Read This Software Agreement Carefully. You Will Have
                    Accepted This Software Agreement When You (1) Access Or Use
                    The Software Services; (2) Click The "I Accept" Or Similar
                    Button Or Acknowledgement Regarding This Software Agreement
                    Linked To Or Otherwise Presented In Conjunction With The
                    Software Services; Or (3) By Agreeing To Other Terms Such As
                    A Quote, Invoice, Statement Of Work, Or Other Document Or
                    Agreement Referencing This Software Agreement, Such
                    Acceptance Including Without Limitation Signing Such
                    Documents Or Your Use Of Or Payment For The Software Services
                    Described In Such Documents.
                  </p>

                  <h5>1. CERTAIN DEFINITIONS.</h5>
                  <p>
                    In addition to other terms that are defined as set forth in
                    this Software Agreement, the following terms shall have the
                    following definitions:
                  </p>
                  <p>1.1. "Device" means your computer, tablet, smartphone, or any other electronic device.</p>
                  <p>
                    1.2. “Intellectual Property” means any and all of the
                    following in any jurisdiction throughout the world and all
                    rights in, arising out of, or associated therewith...
                  </p>
                  <p>
                    1.3. "Modifications" means additional or modified
                    functionality, updates, enhancements, security updates and
                    patches, and upgrades to the Software Services...
                  </p>
                  <p>1.4. “Users” means each individual user of the Software Services.</p>

                  <h6>2. TITLE AND OWNERSHIP OF THE SOFTWARE SERVICES.</h6>
                  <p>
                    As between the parties, title to and ownership of the
                    Software Services and all copies thereof remain with Khet-E...
                  </p>

                  <h6>3. LICENSE AND RIGHT TO ACCESS AND USE THE SOFTWARE SERVICES.</h6>
                  <p>
                    Unless otherwise noted in this Software Agreement, and to the
                    fullest extent allowed under any applicable laws, all terms
                    and conditions of this Software Agreement apply to the
                    license and your accessing and using of any and all Software
                    Services...
                  </p>
                  <p>
                    3.1. LICENSE QUANTITIES. The Purchase Details may include
                    limitations on the number of licenses purchased by you...
                  </p>
                  <p>
                    3.2. API ACCESS. Purchase Details may include access to one
                    or more Khet-E APIs, subject to qualifying purchases or other
                    restrictions...
                  </p>

                  <h6>4. RESTRICTIONS ON USE.</h6>
                  <p>
                    You may only use the Software Services pursuant to the terms
                    of this Software Agreement. As a condition of your use of
                    Khet-E ’s Software Services, you warrant to Khet-E  that you
                    will not use the Software Services for any purpose that is
                    unlawful or prohibited by these terms...
                  </p>

                  <h6>5. ACCESS TO THE SOFTWARE SERVICES.</h6>
                  <p>
                    To access the Software Services or some of the resources they
                    offer, you may be asked to provide certain registration,
                    activation, and login details. It is a condition of your use
                    of the Software Services that all the information you provide
                    in relation to the Software Services, be correct, current,
                    and complete...
                  </p>

                  <h6>6. SERVICES.</h6>
                  <p>
                    Services may be offered for your purchase through written
                    agreement between the parties, the Khet-E & stakeholders...
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default TermsCondition;
