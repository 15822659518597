// PaymentSuccess.js
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Card, Modal } from "react-bootstrap";
import JSZip from "jszip";
import { saveAs } from "file-saver";


const PaymentSuccess = () => {
  const location = useLocation();
  const paymentId = location.state?.paymentId;
  const orderId = location.state?.orderId;
  const product = location.state?.product;
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);


  const handleDownloadAllPdfs = async () => {
    if (product?.productDocumentUrls?.length > 0) {
      const documentUrls = product.productDocumentUrls;
  
      if (documentUrls.length === 1) {
        try {
          const pdfUrl = documentUrls[0];
          const response = await fetch(pdfUrl);
          if (!response.ok) {
            throw new Error(`Failed to download file from ${pdfUrl}`);
          }
          const blob = await response.blob();
          const fileName = getFileName(pdfUrl);
          saveAs(blob, fileName);
        } catch (error) {
          alert(`Error downloading file: ${error.message}`);
        }
      } else {
        // If more than one document, download as a ZIP
        const zip = new JSZip();
        try {
          for (const pdfUrl of documentUrls) {
            const response = await fetch(pdfUrl);
            if (!response.ok) {
              throw new Error(`Failed to download file from ${pdfUrl}`);
            }
            const blob = await response.blob();
            const fileName = getFileName(pdfUrl);
            zip.file(fileName, blob);
          }
  
          const content = await zip.generateAsync({ type: "blob" });
          saveAs(content, "documents.zip");
        } catch (error) {
          alert(`Error downloading files: ${error.message}`);
        }
      }
    } else {
      alert("No PDF documents available for download.");
    }
  };

  const getFileName = (url) => {
    return url ? url.split("/").pop() : "Unknown File";
  };

  const handleGoHome = () => {
    sessionStorage.removeItem("homeRefreshed");
    navigate("/", { state: { refresh: true } });
  };

  const handleConfirm = () => {
    setShowModal(false);
    handleGoHome();
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Container className="payment-success-container mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="text-center p-4">
            <Card.Body>
              <h6 className="text-success">Payment Successful!</h6>
              <h6 className="mt-2">Thank you for your payment.</h6>

              <div className="payment-details mt-4">
                <p>
                  <strong>Payment ID:</strong>{" "}
                  {paymentId || "No payment ID found!"}
                </p>
                <p>
                  <strong>Order ID:</strong> {orderId || "No order ID found!"}
                </p>

                {product && (
                  <>
                    <div className="product-info mt-3">
                      {product.productDocumentUrls?.length > 0 ? (
                        <>
                          <p>
                            <strong>Product Documents:</strong>
                          </p>
                          <ul>
                            {product.productDocumentUrls.map((url, index) => (
                              <li key={index}>
                                
                              </li>
                            ))}
                          </ul>
                          <Button
                            variant="secondary"
                            onClick={handleDownloadAllPdfs}
                            className="mt-3"
                          >
                            Download
                          </Button>
                        </>
                      ) : (
                        <p>No PDF documents available for download.</p>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className="mt-3">
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  Go to Home
                </Button>

                <Modal show={showModal} onHide={handleCancel}>
                  <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                      Confirmation
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="text-dark">
                    Please make sure you have downloaded your document. Do you
                    want to continue?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleConfirm}>
                      Yes
                    </Button>
                    <Button variant="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentSuccess;
