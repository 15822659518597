import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import "../assets/css/product.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../components/Navbar";
import defaultImg from "../assets/images/_banner image for product 1440 x 400/banner-img-product.png";

function ProductCategory() {
  const baseUrl = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchProductsByCategory = async (
    category_id,
    category_image,
    category_name,
    category_description
  ) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/adv-product/category/${category_id}`
      );
      if (response.status === 200) {
        navigate(`/product-category/${category_id}/products`, {
          state: {
            products: response.data.data,
            categoryImage: category_image,
            categoryName: category_name,
            categoryDescription: category_description,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/product/categories`);
        if (response.status === 200) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [baseUrl]);

  return (
    <>
      <Navbar />
      <div className="mb-5">
        <Container fluid className="my-5">
          <Row className="text-center mt-5">
            <Col className="text-center mt-2">
              <img
                src={require("../assets/images/_banner image for product 1440 x 400/Need 1 banner image for product [1440 x 400 (Desktop) (2).png")}
                className="mb-4 mt-4 img-fluid products-image"
                alt="Product"
              />
            </Col>
          </Row>

          <Row className="mt-3 text-center d-flex justify-content-center">
            {categories.length > 0 ? (
              categories.map((category) => (
                <Col key={category._id} md={3}>
                  <div
                    className="categories"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      fetchProductsByCategory(
                        category._id,
                        category.imageUrl,
                        category.name,
                        category.description

                      )
                    }
                  >
                    <img
                      src={category.imageUrl ? category.imageUrl : defaultImg}
                      alt={category.name}
                      className="category-image"
                    />
                  </div>
                  <p className="category-title">{category.name}</p>
                </Col>
              ))
            ) : (
              <p>Loading categories...</p>
            )}
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default ProductCategory;
