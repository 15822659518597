import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Pagination,
  Modal,
  Button,
} from "react-bootstrap";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import share from "../../assets/images/share.png";
import linkImg from "../../assets/images/logo/fi_link.png";
import facebookImg from "../../assets/images/logo/facebook.png";
import whatsappImg from "../../assets/images/logo/whatsapp.png";
import noBlogsImg from "../../assets/images/blog-img.jpg"; 
import "../../assets/css/blog.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Blog = () => {
  const baseUrl = process.env.REACT_APP_BASEURL;

  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [showShareModal, setShowShareModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");

  const totalPages = Math.ceil(articles.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentArticles = articles.slice(startIndex, startIndex + itemsPerPage);
  const [currentArticleId, setCurrentArticleId] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/blog`);
        setArticles(response.data.data);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogData();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openShareModal = (articleId) => {
    setCurrentArticleId(articleId);
    setShowShareModal(true);
  };

  const closeShareModal = () => {
    setCopySuccess(false);
    setShowShareModal(false);
  };
  const handleCopyLink = () => {
    const currentUrl = `${window.location.origin}/blog-details/${currentArticleId}`;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setCopySuccess("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        setCopySuccess("Failed to copy the link.");
      });
  };

  const getCurrentUrl = () => window.location.href;

  const shareOnFacebook = () => {
    const url = getCurrentUrl();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  const shareOnWhatsapp = () => {
    const url = getCurrentUrl();
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  const handleBlogDetail = (id) => {
    navigate(`/blog-details/${id}`);
  };

  const truncateTitle = (title) => {
    return title.length > 30 ? title.slice(0, 30) + "..." : title;
  };

  return (
    <>
      <Navbar />
      <div className="mb-5">
        <Container className="mt-5">
          {articles.length === 0 ? (
            <div className="text-center">
              <img
                src={noBlogsImg}
                alt="No Blogs Available"
                className="blog-main-img"
              />
              {/* <p>No blogs available at the moment.</p> */}
            </div>
          ) : (
            <>
              <Row className="g-4 mt-5">
                {currentArticles.map((article, index) => (
                  <Col key={index} md={4} className="mt-5">
                    <Card className="border-0">
                      <Card.Img
                        variant="top"
                        className="blog-image"
                        src={article.blogImage}
                        alt={article.title}
                      />
                      <Card.Body>
                        <Card.Title
                          className="blog-title mt-2"
                          onClick={() => handleBlogDetail(article._id)}
                        >
                          {article.title}
                        </Card.Title>
                        <div className="d-flex justify-content-between align-items-center">
                          <Button
                            variant=""
                            className=" btn-blog-primary"
                            onClick={() => handleBlogDetail(article._id)}
                          >
                            READ MORE
                          </Button>
                          <button
                            onClick={() => openShareModal(article._id)}
                            className="btn share-btn btn-outline-white ms-1 "
                          >
                            <img
                              src={share}
                              className="mb-1"
                              style={{
                                width: "20px",
                                height: "20px",
                                filter: "brightness(0) invert(1)",
                              }}
                              alt="Share"
                            />
                            <span> Share</span>
                          </button>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>

              {articles.length > 0 && (
                <Row className="mt-4">
                  <Col className="d-flex justify-content-center align-items-center">
                    <Pagination className="pagination-details">
                      <Pagination.First onClick={() => handlePageChange(1)} />
                      <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      />
                      {[...Array(totalPages)].map((_, idx) => {
                        const pageNum = idx + 1;
                        if (pageNum <= 3 || pageNum === totalPages) {
                          return (
                            <Pagination.Item
                              key={pageNum}
                              active={pageNum === currentPage}
                              onClick={() => handlePageChange(pageNum)}
                            >
                              {pageNum}
                            </Pagination.Item>
                          );
                        }
                        if (pageNum === 4 && currentPage < 4) {
                          return <Pagination.Ellipsis key="ellipsis" />;
                        }
                        return null;
                      })}
                      <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      />
                      <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                    </Pagination>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Container>
      </div>

      {/* Share Modal */}
      <Modal
        show={showShareModal}
        onHide={closeShareModal}
        dialogClassName="custom-modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "16px", color: "#0b6938" }}>
            Share this product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row
            className="text-center d-flex justify-content-center position-relative"
            style={{ marginBottom: "20px" }}
          >
            <Col xs="auto" className="d-flex flex-column align-items-center">
              <div
                className="rounded-circle d-flex align-items-center justify-content-center"
                role="button"
                onClick={handleCopyLink}
                style={{
                  width: "40px",
                  height: "40px",
                  border: "1px solid #0b6938",
                }}
              >
                <img
                  src={linkImg}
                  style={{ width: "25px", height: "25px" }}
                  alt="Copy Link"
                />
              </div>
            </Col>

            <Col xs="auto" className="d-flex flex-column align-items-center">
              <div
                className="rounded-circle d-flex align-items-center justify-content-center"
                role="button"
                onClick={shareOnFacebook}
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#0b6938",
                }}
              >
                <img
                  src={facebookImg}
                  style={{ width: "30px", height: "30px" }}
                  alt="facebook"
                />
              </div>
            </Col>

            <Col xs="auto" className="d-flex flex-column align-items-center">
              <div
                className="rounded-circle d-flex align-items-center justify-content-center"
                role="button"
                onClick={shareOnWhatsapp}
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#0b6938",
                }}
              >
                <img
                  src={whatsappImg}
                  style={{ width: "30px", height: "30px" }}
                  alt="whatsapp"
                />
              </div>
            </Col>

            <div
              className="copy-text"
              style={{
                opacity: copySuccess ? 1 : 0,
                visibility: copySuccess ? "visible" : "hidden",
              }}
            >
              Link copied to clipboard!
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};

export default Blog;
